<template>
    <div class="wraper" >
        <Header :isShow="isShow" />
        <div class="banner">
            <img src="@/assets/images/cxbg.jpg" alt="" />
        </div>
        <div class="content max_big_box" ref="header" >
             <div class="title max_small_box">
                <p >报告结果</p>
            </div>
            <div class="line max_small_box"></div>
            <div v-if="data" >
                <!-- <a-input-search
                    class="max_small_box"
                    v-model:value="searchValue"
                    placeholder="金银珠宝证书编号"
                    enter-button="搜索"
                    size="large"
                    @search="onSearch"
                /> -->
                <div class="report_box" id="report" ref="report">
                    <div class="report">
                        <!-- <div class="top ">
                            <div class="first max_small_box">
                                <p class="title"><span>查</span>询报告</p>
                            </div>
                            <div class="line max_big_box"></div>
                            <p class="desc max_small_box">ENQUIRY&nbsp;&nbsp;REPORT</p>
                        </div> -->
                        <div class="under max_small_box" data-aos="fade-down">
                            <div class="first item">
                                <select v-model="reportValue" >
                                    <option v-for="item in reportList" :value="item.id" :key="item.name">{{ item.name }}</option>
                                </select>
                            </div>
                            <div class="second item" v-if="reportValue==0">
                                <input placeholder="单号" v-model="WTDH" />
                            </div>
                            <div class="second item" v-if="reportValue==1 || reportValue==2 || reportValue==3 || reportValue==4">
                                <input placeholder="证书号" v-model="WTDH2" />
                            </div>
                            <div class="second item" v-if="reportValue==5">
                                <input placeholder="报告编号" v-model="BGBH" />
                            </div>
                            <div class="third item" v-if="reportValue==1 || reportValue==0">
                                <input :placeholder="reportValue==1?'校验码':'验证码（请联系业务对接人获取）'" v-model="YZM" />
                            </div>
                            <div class="third item" v-if="reportValue==5">
                                <input placeholder="防伪码" v-model="FWM" />
                            </div>
                            <div class="fourth item">
                                <button @click="submit">提交</button>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-if="data.length == 0" style="text-align: center;padding-top: 60px;">暂无报告结果...</p>
                <a-table v-else class="max_small_box" style="margin-top: 60px" :columns="columns" :data-source="data" :pagination="false" rowKey="id">
                    <a >{{ text }}</a>
                </a-table>
            </div>
            <!-- <div class="line"></div> -->
        </div>
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref, watchEffect, defineComponent,reactive, toRefs  } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//查询报告逻辑
const reportEffect = (router) => {
    const reporSelect = reactive({
        reportList: [
            // {
            //     id: 0,
            //     name: '检验报告查询'
            // },
            // {
            //     id: 1,
            //     name: '金银珠宝证书查询'
            // },
            // {
            //     id: 2,
            //     name: '红木家具质量证书查询'
            // },
            // {
            //     id: 3,
            //     name: '电子电器证书查询'
            // },
            // {
            //     id: 4,
            //     name: '培训证书查询'
            // },
            // {
            //     id: 5,
            //     name: '报告防伪查询'
            // },
        ],
        reportValue: 99,  //选中框的值
        WTDH: '',  //单号
        WTDH2: '',  //证书号
        YZM: '', //验证码
        BGBH: '', //报告编号
        FWM: '', //防伪码
        wtdhlink: '',// 单号查询链接
        bgbhlink: '',// 报告防伪查询链接
        zbcxlink: '',  // 珠宝查询链接
    })
    
    //请求链接
    const getLink = async () => {
        try{
            const result = await get('/index/get_Link', {})
            if(result.code == 1){
                for(let i=0;i<result.data.length;i++){
                    if(result.data[i].name==='wtdhlink'){
                        wtdhlink.value = result.data[i].value
                    }
                    if(result.data[i].name==='bgbhlink'){
                        bgbhlink.value = result.data[i].value
                    }
                    if(result.data[i].name==='zbcxlink'){
                        zbcxlink.value = result.data[i].value
                    }
                }
            }else{
                // console.log('请求失败')
            }
        }catch(e) {
        
        }
    }
    const getReportList = async () => {
        try{
            const result = await get('/index/get_report', {})
            // console.log('查询报告', result)
            if(result.code == 1){
                let list = result.data
                for(let i=0;i<result.data.length;i++){
                    list[i].id = parseInt(result.data[i].tip)
                    list[i].name = result.data[i].title
                }
                reportList.value = list
                reportValue.value = list[0].id
            }
        }catch(e) {
        
        }
    }

    //提交
    const submit = () => {
        console.log(reporSelect.reportValue)
        // if(reporSelect.WTDH==""){
		// 	alert("请输入委托单号");
		// 	return false;
		// }
		// if(reporSelect.BGBH==""){
		// 	alert("请输入报告编号");
		// 	return false;
		// }

		if(reporSelect.reportValue==0){
            // http://qtims.gddqt.com/QTIMSWeb/JYBGCX.aspx?WTDH=123&YZM=123
            // var url = "http://qtims.gddqt.com/JYBGCX.aspx?WTDH="+reporSelect.WTDH+"&YZM="+reporSelect.YZM;
            var url = reporSelect.wtdhlink+"?WTDH="+reporSelect.WTDH+"&YZM="+reporSelect.YZM;
            window.open(url,"","width=800,height=600,top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no");
		}
		if(reporSelect.reportValue==1){
            if(reporSelect.WTDH2 ==''){
                alert("请输入证书号");
            }else{
                let code = reporSelect.WTDH2, wtdh = reporSelect.WTDH2
                if(wtdh.substring(0, 3) === 'DG1'){
                    if(reporSelect.YZM ==''){
                        alert("请输入校验码");
                        return
                    }
                    var url = reporSelect.zbcxlink +"?certification_code="+code+"&order_code="+reporSelect.YZM
                    window.open(url,"","width=800,height=600,top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no");
                }else{
                    router.push({ path: `/reoprt_detail/${reporSelect.WTDH2}` })
                }
            }
		}
		if(reporSelect.reportValue==2){
		    if(reporSelect.WTDH2 ==''){
                alert("请输入证书号");
            }else{
                router.push({ path: `/reoprt_detail/${reporSelect.WTDH2}` })
            }
		}
		if(reporSelect.reportValue==3){
		    if(reporSelect.WTDH2 ==''){
                alert("请输入证书号");
            }else{
                router.push({ path: `/reoprt_detail/${reporSelect.WTDH2}` })
            }
		}
		if(reporSelect.reportValue==4){
           if(reporSelect.WTDH2 ==''){
                alert("请输入证书号");
            }else{
                router.push({ path: `/reoprt_detail/${reporSelect.WTDH2}` })
            }
		}
		if(reporSelect.reportValue==5){
		    // var url = "http://www.gddqt.com/QTIMSWeb/BGFWCX.aspx?BGBH="+reporSelect.BGBH+"&FWM="+reporSelect.FWM;
		    // var url = "http://qtims.gddqt.com/BGFWCX.aspx?BGBH="+reporSelect.BGBH+"&FWM="+reporSelect.FWM;
		    var url = reporSelect.bgbhlink + "?BGBH="+reporSelect.BGBH+"&FWM="+reporSelect.FWM;
            window.open(url,"","width=800,height=600,top=100, left=100, toolbar=no, menubar=no, scrollbars=yes, resizable=yes,location=no, status=no");
		}
    }


    const { reportList, reportValue, WTDH, WTDH2, YZM, BGBH, FWM, wtdhlink, bgbhlink, zbcxlink} = toRefs(reporSelect)

    return { reportList, reportValue, WTDH, WTDH2, YZM, BGBH, FWM, submit, getReportList, getLink }
}

//详情处理模块
const detailEffect = () => {
    const route = useRoute();
    const router = useRouter();

    const columns = [
        {
            title: '证书编号',
            dataIndex: 'certificate',
            key: 'certificate',
        },
        {
            title: '检验结果',
            dataIndex: 'results',
            key: 'results',
        },
        {
            title: '形状',
            dataIndex: 'shape',
            key: 'shape',
        },
        {
            title: '总质量',
            dataIndex: 'quality',
            key: 'quality',
        },
        {
            title: '钻石标重',
            dataIndex: 'diamond',
            key: 'diamond',
        },
        {
            title: '颜色',
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: '镶嵌材料',
            dataIndex: 'material',
            key: 'material',
        },
        {
            title: '净度',
            dataIndex: 'clarity',
            key: 'clarity',
        },
        {
            title: '切工',
            dataIndex: 'cut',
            key: 'cut',
        },
        {
            title: '鉴定者',
            dataIndex: 'qualifier',
            key: 'qualifier',
        },
        {
            title: '检查',
            dataIndex: 'inspectors',
            key: 'inspectors',
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
        },
    ];

    const data = ref()

    const searchValue = ref()

    const getList = async () => {
        // console.log(route)
        await post('/other/search_report', {certificate: route.params.id}).then((res) => {
            // console.log('查询报告',res)
            data.value = res.data
        })
    }

    const onSearch = () => {
        router.push({ path: `/reoprt_detail/${searchValue.value}` })
    }

    watchEffect(() => {
        getList()
        if(route.params.id != ' '){
            searchValue.value = route.params.id
        }
        
    })

    return { getList, columns, data, onSearch, searchValue }
}

export default defineComponent ({
    name: 'ReoprtDetail',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute();
        const router = useRouter();
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //查询报告逻辑
        const { 
                reportList, 
                reportValue, 
                WTDH, 
                WTDH2, 
                YZM, 
                BGBH, 
                FWM,
                submit,
                getReportList, getLink
            } = reportEffect(router)

        //详情处理模块
        const { getList, columns, data, onSearch, searchValue } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        getReportList()  // 查询报告
        getLink()  // 查询链接
        const { imgUrl }  = api

        return { imgUrl, isShow, header, columns, data, onSearch, searchValue, reportList, reportValue, WTDH, WTDH2, YZM, BGBH, FWM,submit, getReportList,
                getLink }
    }
})
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
.content{
    margin-top: 50px;
    .title{
        text-align: center;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: bold;
        }
    }
    .line{
        width: 100%;
        height: 2px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .detail{
        margin: 30px;
    }
}
/* 查询报告 */
.report_box{
    margin-top: 60px;
    .report{
        .top{
            .first{
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                @media screen and (max-width: 1200px){
                    padding: 0 30px;
                }
                .title{
                    font-size: 20px;
                    @media screen and (max-width: 900px){
                        font-size: .16rem;
                    }
                    span{
                        font-size: 40px;
                        @media screen and (max-width: 900px){
                            font-size: .3rem;
                        }
                    }
                }
            }
            .line{
                margin-top: 8px;
                height: 2px;
                background: rgba(187, 187, 187, 100);
            }
            .desc{
                margin-top: 14px;
                font-size: 20px;
                color: #777777;
                @media screen and (max-width: 1200px){
                    padding: 0 30px;
                }
                @media screen and (max-width: 900px){
                    font-size: .16rem;
                }
            }
        }
        .under{
            margin-top: 50px;
            margin-bottom: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 1200px){
                padding: 0 30px;
            }
            @media screen and (max-width: 900px){
                display: block;
                padding: 0 60px;
            }
            .item{
                width: 22%;
                @media screen and (max-width: 900px){
                    width: 100%;
                    margin: 0 auto;
                    margin-bottom: 15px;
                }
                select{
                    width: 100%;
                    outline: none;
                    border: 1px solid rgba(187, 187, 187, 100);
                    padding: 15px 10px;
                    color: #868686;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    cursor: pointer;
                    font-size: 16px;
                    background: url("../../assets/images/jiantou.png") no-repeat scroll 230px center transparent;
                    background-size: 15px 15px;
                    background-color: #fff;
                    @media screen and (max-width: 900px){
                        background: none;
                        background-color: #fff;
                        font-size: .14rem;
                    }
                }
                input{
                    width: 100%;
                    outline: none;
                    border: 1px solid rgba(187, 187, 187, 100);
                    padding: 15px 10px;
                    color: #868686;
                    font-size: 16px;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                    }
                }
                button{
                    width: 80%;
                    background-color: rgba(47, 80, 158, 100);
                    font-size: 16px;
                    border: none;
                    color: #fff;
                    padding: 15px 0;
                    cursor: pointer;
                    @media screen and (max-width: 900px){
                        font-size: .14rem;
                    }
                }
            }
            .fourth{
                text-align: right;
                @media screen and (max-width: 900px){
                    text-align: center;
                }
            }
        }
    }
}
.pagination{
    display: block;
    text-align: center;
    padding-top: 80px;
}
.ant-input-group-wrapper{
    display: block;
}
</style>